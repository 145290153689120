import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import styled from 'styled-components';

const languageName = {
  es: 'es',
  ca: 'cat',
};

const Language = ({ className }) => {
  return (
    <div className={className}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <button
              key={language}
              href={null}
              onClick={() => changeLocale(language)}
              className={`lang-link ${
                currentLocale === language ? 'selected' : ''
              }`}
            >
              {languageName[language]}
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  );
};

// export default Language;

export default styled(Language)`
  position: absolute;
  right: 1em;
  top: 1em;
  .lang-link {
    cursor: pointer;
    margin: 5px;
    border: none;
    font-size: 0.75em;
    padding: 0 1.75em;
  }
  .selected {
    color: white;
    background-color: #b8ae8b;
  }
  @media (max-width: 481px) {
    .lang-link {
      font-size: 0.65em;
    }
  }
`;
