module.exports = {
  siteTitle: 'Celler del Minifundi', // <title>
  manifestName: 'cellerminifundi',
  manifestShortName: 'minifundi', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#0b1b2b',
  manifestThemeColor: '#0b1b2b',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  heading: 'Celler del Minifundi',
  siteUrl: 'http://cellerminifundi.com',
  description:
    'Celler del Minifundi ®️ es una nueva senda en la vitivinicultura, más pequeña y al estilo de Juan Cascant',
  keywords:
    'minifundi, celler, bodega, cellar, vino, vi, wine, minifundi, Muro de Alcoy, Microvinya, Cavall de foc, Vermut Ferrer, Alcoy, Valencia, Juan Cascant, botella vermut, botella vino, catalogo vino',
  twitterName: '@microvinya',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/cellerminifundi',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/microvinya',
    },
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/CellerdelMinifundi',
    },
    {
      style: 'brands',
      icon: 'fa-telegram',
      name: 'Telegram',
      url: 'https://t.me/joinchat/AAAAAE9c29irQlLkIBtpzA',
    },
  ],
};
