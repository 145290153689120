import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';

import '../assets/sass/main.scss';
import Footer from './Footer';
import Language from './Language';

class Layout extends Component {
  render() {
    const { children, intl } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                siteUrl
                description
                keywords
                twitterName
              }
            }
            metaImage: file(relativePath: { eq: "minifundi.png" }) {
              childImageSharp {
                fixed(width: 800) {
                  src
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              htmlAttributes={{ lang: 'ca' }}
              title={data.site.siteMetadata.title}
            >
              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />
              <meta name="keywords" content={data.site.siteMetadata.keywords} />
              <meta
                name="image"
                content={`${data.site.siteMetadata.siteUrl}${data.metaImage.childImageSharp.fixed.src}`}
              />
              {/* facebook cards */}
              <meta
                property="og:url"
                content={data.site.siteMetadata.siteUrl}
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content={data.site.siteMetadata.title}
              />
              <meta
                property="og:description"
                content={data.site.siteMetadata.description}
              />
              <meta
                property="og:image"
                content={`${data.site.siteMetadata.siteUrl}${data.metaImage.childImageSharp.fixed.src}`}
              />
              <meta property="og:image:width" content="400" />
              <meta property="og:image:height" content="300" />
              {/* twitter card */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:creator"
                content={data.site.siteMetadata.twitterUsername}
              />
              <meta
                name="twitter:title"
                content={data.site.siteMetadata.title}
              />
              <meta
                name="twitter:description"
                content={data.site.siteMetadata.description}
              />
              <meta
                name="twitter:image"
                content={`${data.site.siteMetadata.siteUrl}${data.metaImage.childImageSharp.fixed.src}`}
              />
            </Helmet>
            <div className="landing main-body is-preload">
              <Language />
              <div id="page-wrapper">
                {children}
                <Footer />
              </div>
            </div>
            <CookieConsent
              location="bottom"
              buttonText={intl.formatMessage({ id: 'accept' })}
              containerClasses="cookie-coontainer"
              buttonClasses="button primary cookie-button"
              cookieName="gatsby-gdpr-google-tagmanager"
              acceptOnScroll={true}
            >
              <FormattedMessage id="cookiesText" />
              <Link className="politica-cookies" to="/cookies">
                <FormattedMessage id="cookiesPolicy" />
              </Link>
              .
            </CookieConsent>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default injectIntl(Layout);
