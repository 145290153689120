import React from 'react';
import styled from 'styled-components';

const Newsletter = ({ className }) => {
  return (
    <div className={className}>
      <iframe
        title="newsletter"
        className="newsletter-iframe"
        src="https://a8d3e797.sibforms.com/serve/MUIEAFHLdlbDpQy8d48K08ayk2AUL-6Ia3chQj8OyRgf-dIlY7zKwWtQ3_zzbT-G341Yi8YQH2M04zfzT3Mxkkf7tqFkpLcz0vA18fF7ZfWrK5SNM-a4PZPwcxKweOxG91-GW8e9cTVkJzz7VHuGaNWAoK5mWxM2pVcOUSg0VFBKXezFoe8JPpfAC_YdzzrlXJmiJn4RltQHEeni"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default styled(Newsletter)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .newsletter-iframe {
    margin-bottom: 20px;
    width: 100%;
    height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  @media (max-width: 481px) {
    .newsletter-iframe {
      height: 600px;
    }
  }
`;
