import React from 'react';
import config from '../../config';
import Newsletter from './Newsletter';
import Img from 'gatsby-image';

import { useStaticQuery, graphql } from 'gatsby';

const getMicrovinya = graphql`
  query {
    microvinya: file(relativePath: { eq: "Microvinya.png" }) {
      childImageSharp {
        fluid(maxHeight: 295) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default function Footer() {
  const { microvinya } = useStaticQuery(getMicrovinya);

  return (
    <footer id="footer">
      <Newsletter />
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <div className="image-container">
        <a
          href="http://microvinya.cellerminifundi.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Img
            className="microvinya-logo"
            fluid={microvinya.childImageSharp.fluid}
            alt="Microvinya"
          />
        </a>
      </div>
      <ul className="copyright">
        <li> {new Date().getFullYear()} &copy; Celler del Minifundi</li>
        <li>
          <a
            href="http://facundfortuny.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="label">Design by Facund Fortuny</span>
          </a>
        </li>
      </ul>
    </footer>
  );
}
